<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" class="mt-16" v-if="canManageNotifications">
    <div>
      <Breadcrumbs class="inlineblock" />
    </div>

    <v-container :fluid="isMobile">
      <v-card>
        <v-container fluid>
          <v-row v-for="langue of availableLanguages" v-bind:key="langue.value">
            <v-col :class="{ 'errored': showError && !notification[langue.value] }" cols="4">
              {{ langue.text }}
            </v-col>
            <v-col>

              <v-text-field
                            v-model="notification[langue.value]"
                            label=""
                            hide-details
                            maxlength="144"
                            dense />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                              dense
                              :class="{ 'errored': showError && this.engineeringDepartmentsSelected.length == 0, 'required': true }"
                              :items="engineeringDepartments"
                              :label="$t('general.engineering_departments')"
                              item-text="name"
                              item-value="id"
                              return-object
                              v-model="engineeringDepartmentsSelected"
                              multiple
                              chips></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                              dense
                              :class="{ 'errored': showError && this.rolesSelected.length == 0, 'required': true }"
                              :items="roles"
                              :label="$t('users.role')"
                              item-text="name"
                              item-value="id"
                              return-object
                              v-model="rolesSelected"
                              multiple
                              chips></v-autocomplete>
            </v-col>
          </v-row>
          <br />
          <v-btn color="green white--text" @click="confirm = validate()" :disabled="inProgress">

            {{ $t("general.send") }}
          </v-btn>

        </v-container>
      </v-card>
    </v-container>
    <ConfirmDialog
                   v-if="confirm"
                   v-model="confirm"

                   :title="$t('confirm.head')"
                   :description="$t('notifications.confirm')"

                   :cancelName="$t('reportInfo.no')"
                   :actionName="$t('reportInfo.yes')"
                   :actionFunction="send" />

      <center><v-btn @click="sendPushExemple()">Push Exemple</v-btn></center>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { getLanguage } from "@/utils/utils";
import { languageList } from '@/utils/languages';
import UserService from "@/services/user.service";
import Breadcrumbs from "@/components/Breadcrumbs";
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import NotificationManualService from "@/services/notificationManual.service";

export default {
  name: "NotificationManual",
  components: {
    Breadcrumbs,
    ConfirmDialog
  },
  data: () => {
    return {
      inProgress: false,
      showError: false,
      notification: {},
      engineeringDepartments: [],
      engineeringDepartmentsSelected: [],
      roles: [],
      rolesSelected: [],
      availableLanguages: languageList(),
      confirm: false
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      currentUser: state => state.currentUser
    }),
    ...mapGetters({
      canManageNotifications: 'canManageNotifications'
    }),
  },
  methods: {
    ...mapMutations({
      openToast: 'openToast',
    }),
    ...mapActions({
      setBreadcrumbs: "setBreadcrumbs",
    }),

    validate() {
      // Valider le formulaire
      this.showError = true;

      for (const language of this.availableLanguages) {
        if (!this.notification[language.value]) {
          this.openToast({ message: this.$t("validation.required") + ' : ' + language.text, duration: 15000, color: 'red' });
          return false;
        }
      }
      if (this.engineeringDepartmentsSelected.length == 0) {
        this.openToast({ message: this.$t("validation.required") + ' : ' + this.$t("general.engineering_departments"), duration: 15000, color: 'red' });
        return false;
      }
      if (this.rolesSelected.length == 0) {
        this.openToast({ message: this.$t("validation.required") + ' : ' + this.$t("users.role"), duration: 15000, color: 'red' });
        return false;
      }

      return true;
    },

    async sendPushExemple() {
      if (!window.Notification) {
            console.log('Browser does not support notifications.');
        } else {
            // check if permission is already granted
            if (Notification.permission === 'granted') {
                // show notification here
                var notify = new Notification('Hi there1!', {
                    body: 'How are you doing1?',
                    image: 'https://via.placeholder.com/149',
                });
                console.log(notify);
            } else {
                // request permission from user
                Notification.requestPermission().then(function (p) {
                    if (p === 'granted') {
                        // show notification here
                        var notify = new Notification('Hi there2!', {
                            body: 'How are you doing3?',
                            image: 'https://via.placeholder.com/149',
                        });
                        console.log(notify);
                    } else {
                        console.log('User blocked notifications.');
                    }
                }).catch(function (err) {
                    console.error(err);
                });
            }
        }
    },



    async send() {
      // Envoyer
      this.inProgress = true; // v-btn disabled
      this.confirm = false;
      this.showError = false;
      NotificationManualService.send({
        notification: this.notification,
        engineeringDepartments: this.engineeringDepartmentsSelected,
        roles: this.rolesSelected
      });

      // Confirmation & Reset
      this.openToast({ message: this.$t("notifications.confirmation"), duration: 15000 });
      this.engineeringDepartmentsSelected = this.engineeringDepartments;
      this.rolesSelected = this.roles;
      Object.keys(this.notification).forEach(k => this.notification[k] = '');
      this.inProgress = false;
    },
  },

  async mounted() {
    this.setBreadcrumbs([{ text: this.$t("general.settings"), href: "/settings" }, { text: this.$t("general.notifications") }]);

    let result = await UserService.getUserPageData(this.currentUser.id, getLanguage());
    this.engineeringDepartments = this.engineeringDepartmentsSelected = result.data.engineeringDepartments.map((e) => ({ id: e.id, name: e.name }));
    this.roles = this.rolesSelected = result.data.roles.map((e) => ({ id: e.id, name: e.name }));


    // TODO: Please remove - START
    /*
    for (const language of this.availableLanguages){
      this.notification[language.value] = 'Test... ' + language.value;
    }
    this.send();
    */
    // TODO: Please remove - END
  }
};
</script>

<style scoped></style>